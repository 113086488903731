/* 
The useMediaQuery and useBreakpoints functions manage media queries and breakpoints.

useMediaQuery takes a media query string and returns a boolean indicating whether it matches the current window size.
useBreakpoints utilizes useMediaQuery to manage different breakpoints, providing a responsive design framework.
*/

import { useEffect, useLayoutEffect, useState } from 'react';

// Handle media queries - used in useBreakpoints
export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    // Set initial match state
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    // Listener to update match state on change
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);

    // Cleanup listener on unmount
    return () => window.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

// Custom hook to manage different breakpoints
export function useBreakpoints() {
  const [isClient, setIsClient] = useState(false);

  const breakpoints = {
    isXs: useMediaQuery('(max-width: 640px)'),
    isSm: useMediaQuery('(min-width: 641px) and (max-width: 768px)'),
    isMd: useMediaQuery('(min-width: 769px) and (max-width: 1024px)'),
    isLg: useMediaQuery('(min-width: 1025px)'),
    active: 'SSR', // Server-side rendering by default
  };

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  if (isClient && breakpoints.isXs) breakpoints.active = 'xs';
  if (isClient && breakpoints.isSm) breakpoints.active = 'sm';
  if (isClient && breakpoints.isMd) breakpoints.active = 'md';
  if (isClient && breakpoints.isLg) breakpoints.active = 'lg';

  return breakpoints;
}
