'use client';

import {
  ARIA_LABEL_CATEGORIES_LIST,
  ARIA_LABEL_CATEGORIES_LIST_LONG,
  ARIA_LABEL_SUB_NAV,
  ARIA_LABEL_TOP_ARTICLES,
  URL_PAGE_ALWAYS,
} from '@/shared/constants';
import { cn } from '@/utils/cn';
import React, { useEffect, useState } from 'react';
import { ArticleCard as ArticleCardType } from '@/sanity/fetchArticles';
import { CategoryLink } from '@/sanity/fetchCategories';
import NavigationCategoriesList from './NavigationCategoriesList';
import ArticlesList from './ArticlesList';
import { useBreakpoints } from '@/utils/useBreakpoints';

function IconCaret({ rotate }: { rotate?: boolean }) {
  return (
    <svg
      className={cn(
        'motion-safe:transform motion-safe:transition-transform',
        rotate && ' rotate-180',
      )}
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M0.25 0.125L4 3.875L7.75 0.125H0.25Z" fill="#1D1B20" />
    </svg>
  );
}

interface NavigationSubMenuProps {
  title: string;
  articles?: ArticleCardType[];
  categories?: CategoryLink[];
}

const NavigationSubMenu: React.FC<NavigationSubMenuProps> = ({
  title,
  articles,
  categories,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const id = 'sub-menu';
  const { isLg } = useBreakpoints();

  useEffect(() => {
    const removeListener = () => {
      document.body.classList.remove('submenu-overlay');
      document.body.removeEventListener('click', (e) => {
        if (e.target === document.body) setIsSubMenuOpen(false);
      });
    };

    if (isSubMenuOpen && isLg) {
      document.body.classList.add('submenu-overlay');
      document.body.addEventListener('click', (e) => {
        if (e.target === document.body) setIsSubMenuOpen(false);
      });
    } else if (!isSubMenuOpen || !isLg) {
      removeListener();
    }

    return () => {
      removeListener();
    };
  }, [isSubMenuOpen, isLg]);

  return (
    <>
      <li className="py-lg lg:py-0">
        <a
          href={URL_PAGE_ALWAYS}
          className="
            text-title-md text-sys/surface/on-surface
            rounded-full px-lg py-xs -ml-lg lg:ml-0
            motion-safe:transition-colors
            hover:bg-sys/surface/on-surface/8
            focus:bg-sys/secondary/surface/on-surface/12
            active:bg-sys/secondary/surface/on-surface/16
          "
        >
          {title}
        </a>
      </li>

      <li className="py-lg lg:py-0">
        <button
          onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
          className={cn(
            `
            flex items-center gap-x-lg font-medium text-sys/surface/on-surface
            rounded-full px-lg py-xs -ml-lg lg:ml-0
            motion-safe:transition-colors
            hover:bg-sys/surface/on-surface/8
            active:bg-sys/secondary/surface/on-surface/16
          `,
            isSubMenuOpen && 'focus:bg-sys/secondary/surface/on-surface/12',
          )}
          aria-expanded={isSubMenuOpen}
          aria-controls={`#${id}`}
          aria-haspopup="true"
        >
          {ARIA_LABEL_CATEGORIES_LIST}
          <IconCaret rotate={isSubMenuOpen} />
        </button>
        <div
          aria-hidden={isSubMenuOpen}
          aria-label={ARIA_LABEL_SUB_NAV}
          id={id}
          className={cn(
            `
              bg-sys/surface/surface lg:absolute lg:top-[80px] lg:left-0 lg:w-full lg:z-10
              hidden motion-safe:lg:block motion-safe:lg:overflow-hidden motion-safe:lg:max-h-0 motion-safe:lg:transition-all
            `,
            isSubMenuOpen &&
              `
                block lg:shadow-[0px_12px_16px_-4px_rgba(16,_24,_40,_0.08),_0px_4px_6px_-2px_rgba(16,_24,_40,_0.03)]
                motion-safe:lg:max-h-[600px]
              `,
          )}
        >
          <div className=" lg:container-content lg:flex lg:gap-x-4xl mt-4xl lg:pb-4xl">
            <div className="lg:basis-2/12 lg:min-w-[170px]">
              <div
                id={ARIA_LABEL_CATEGORIES_LIST_LONG}
                className="text-title-sm text-sys/surface/on-surface-variant lg:mt-lg"
              >
                {ARIA_LABEL_CATEGORIES_LIST_LONG}
              </div>
              {categories && categories.length && (
                <NavigationCategoriesList
                  labelledBy={ARIA_LABEL_CATEGORIES_LIST_LONG}
                  categories={categories}
                  isSubMenuOpen={isSubMenuOpen}
                  onCategoryClick={() => setIsSubMenuOpen(false)}
                />
              )}
            </div>
            {articles && articles.length && (
              <ArticlesList
                label={ARIA_LABEL_TOP_ARTICLES}
                articles={articles}
                isSubMenuOpen={isSubMenuOpen}
                onArticleClick={() => setIsSubMenuOpen(false)}
              />
            )}
          </div>
        </div>
      </li>
    </>
  );
};

export default NavigationSubMenu;
